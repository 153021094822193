// Importing utility functions and React hooks
import classNames from "classnames";
import styles from "./index.module.scss";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";

// Interface defining the props for the RangeSlider component
interface IRangeSliderProps {
  min: number; // Minimum value of the range slider
  max: number; // Maximum value of the range slider
  onChange?: (value: { min: number; max: number }) => void; // Callback function that is called when the range slider value changes
  onMouseUp?: (value: { min: number; max: number }) => void; // Callback function that is called when the mouse is released after dragging the slider
  showLabel?: boolean; // Flag to show/hide the labels indicating the current values of the range slider
  className?: string; // Custom class name for styling the component
}

// RangeSlider functional component definition
function RangeSlider({
  min, // Minimum value of the range slider
  max, // Maximum value of the range slider
  onChange, // Callback function for range value change
  onMouseUp, // Callback function for mouse up event
  showLabel, // Flag to control label visibility
  className, // Custom class name
}: IRangeSliderProps) {
  // State for managing the minimum and maximum values of the range slider
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);

  // References to the minimum and maximum values for use in callback functions
  const minValRef = useRef(min);
  const maxValRef = useRef(max);

  // Reference to the range element for managing its width
  const range = useRef<HTMLDivElement>(null);

  // References to the input elements for the min and max values
  const inputMin = useRef<HTMLInputElement>(null);
  const inputMax = useRef<HTMLInputElement>(null);

  // Function to handle clicks on the slider, allowing users to jump to specific values
  const handleSliderClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const element = event.currentTarget;
    const rect = element.getBoundingClientRect();
    const clickXInsideElement = event.clientX - rect.left;

    const trackClick = (() => {
      if (clickXInsideElement / (element.clientWidth / 100) >= 100) return 100;
      if (clickXInsideElement / (element.clientWidth / 100) <= 0) return 0;

      return clickXInsideElement / (element.clientWidth / 100);
    })();

    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxVal);

    if (Math.abs(trackClick - minPercent) < Math.abs(trackClick - maxPercent)) {
      if (inputMin.current) {
        const newValue = Math.min(
          (trackClick / 100) * (max - min) + min,
          maxVal - 1,
        );

        setMinVal(newValue);
        minValRef.current = newValue;

        if (onChange) {
          onChange({ min: newValue, max: maxVal });
        }

        if (onMouseUp) {
          onMouseUp({ min: newValue, max: maxVal });
        }
      }
    } else {
      if (inputMax.current) {
        const newValue = Math.max(
          (trackClick / 100) * (max - min) + min,
          minVal + 1,
        );

        setMaxVal(newValue);
        maxValRef.current = newValue;

        if (onChange) {
          onChange({ min: minVal, max: newValue });
        }

        if (onMouseUp) {
          onMouseUp({ min: minVal, max: newValue });
        }
      }
    }
  };

  // Function to convert a value to a percentage of the slider's total range
  const getPercent = useCallback(
    (value: number) => Math.round(((value - min) / (max - min)) * 100),
    [min, max],
  );

  // Effects to update the range slider's appearance based on the current min and max values
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  // Render the range slider component
  return (
    <div
      className={classNames(styles.container, className)}
      onClick={handleSliderClick}>
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        onMouseUp={() => {
          if (onMouseUp) {
            onMouseUp({ min: minVal, max: maxVal });
          }
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);

          setMinVal(value);
          minValRef.current = value;

          if (onChange) {
            onChange({ min: value, max: maxVal });
          }
        }}
        ref={inputMin}
        className={classNames(styles.thumb, styles.thumb__left)}
        style={{ zIndex: minVal > max - 100 ? 5 : undefined }}
      />

      <input
        ref={inputMax}
        type="range"
        min={min}
        max={max}
        value={maxVal}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = Math.max(Number(event.target.value), minVal + 1);

          setMaxVal(value);
          maxValRef.current = value;

          if (onChange) {
            onChange({ min: minVal, max: value });
          }
        }}
        onMouseUp={() => {
          if (onMouseUp) {
            onMouseUp({ min: minVal, max: maxVal });
          }
        }}
        className={classNames(styles.thumb, styles.thumb__right)}
      />

      <div className={classNames(styles.slider)}>
        <div className={classNames(styles.slider__track)}></div>
        <div ref={range} className={classNames(styles.slider__range)}></div>
        {showLabel && (
          <React.Fragment>
            <div className={classNames(styles.slider__left__value)}>
              {minVal}
            </div>
            <div className={classNames(styles.slider__right__value)}>
              {maxVal}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

// Default props for the RangeSlider component
RangeSlider.defaultProps = {
  min: 0,
  max: 100,
  showLabel: false,
  className: "",
};
// Export the RangeSlider component
export default RangeSlider;
