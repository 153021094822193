// Importing useState hook from React for managing state.
import { useState } from "react";
// Importing useTranslation hook for internationalization.
import { useTranslation } from "react-i18next";

// Importing custom hooks and utilities from the store and utils.
import { useTypedDispatch, useTypedSelector } from "../../../store/store";
import { validateCreateAgentSession } from "../../../utils/validation";
import { FORGOTTEN_PASSWORD } from "../../../utils/constants/routes";

// Importing reusable components for UI.
import Input from "../../shared/Input";
import Button from "../../shared/Button";

// Importing CSS module for styling.
import styles from "./index.module.scss";
// Importing CustomLink component for internal navigation.
import CustomLink from "../../shared/CustomLink";
// Importing session service hook for API call.
import { useLazyInitAgentSessionQuery } from "../../../store/services/SessionService";
// Importing actions for dispatch.
import { showToast } from "../../../store/slices/toastSlice";
// Constants for toast notifications.
import TOAST from "../../../utils/constants/toast";
// Importing dayjs for date manipulation.
import dayjs from "dayjs";
// Custom hook for managing cookies.
import useCookies from "../../../hooks/useCookies";
import parseTextWithLinks from "../../../utils/helpers/parseTextWithLinks";

// Type definition for handling input changes.
type InputChangeHandler = Record<string, string>;

// Interface for validation inputs.
interface IValidationInputs {
  email: string;
  password: string;
}

// Interface for managing input state.
interface IInputs {
  email: { value: string; errorMessage: string };
  password: { value: string; errorMessage: string };
}

// SignIn component definition.
function SignIn() {
  // Hook for translation functionality.
  const { t } = useTranslation();
  // Custom hook for setting cookies.
  const { setCookie } = useCookies();
  // Dispatch hook for redux actions.
  const dispatch = useTypedDispatch();
  // Custom hook for initiating agent session.
  const [initAgentSession] = useLazyInitAgentSessionQuery();

  // State from the redux store for loading status.
  const { isLoading } = useTypedSelector((state) => state.session);
  const { agency_name, auth_page_background, registration_link_text } =
    useTypedSelector((state) => state.environment);

  // State for managing input fields and validation errors.
  const [inputs, setInputs] = useState<IInputs>({
    email: { value: "", errorMessage: "" },
    password: { value: "", errorMessage: "" },
  });

  // Handler for input changes, updating the state.
  const handleInputChange = ({ value, valueKey }: InputChangeHandler) => {
    setInputs((prev) => ({
      ...prev,
      [valueKey]: { errorMessage: "", value },
    }));
  };

  // Function for logging in, using the validation inputs.
  const login = async (credentials: IValidationInputs) => {
    const { isError } = await initAgentSession(credentials);

    // If there's an error, show a toast notification.
    if (isError) {
      dispatch(
        showToast({
          type: TOAST.ERROR_TYPE,
          message: t("Wrong credentials"),
          duration: TOAST.DEFAULT_DURATION,
        }),
      );

      return;
    }

    // On successful login, set a cookie for session expiration.
    const sessionKeyExp = dayjs().add(3600, "seconds").format();

    setCookie("sessionKey_exp", sessionKeyExp);
  };

  // Handler for form errors, updating the state with error messages.
  const handleFormError = (errors: IValidationInputs) => {
    const updatedInputs = structuredClone(inputs);

    // Loop through the errors object and update the state.
    Object.keys(errors).forEach((errorKey) => {
      if (updatedInputs[errorKey as keyof IInputs]) {
        updatedInputs[errorKey as keyof IInputs].errorMessage =
          errors[errorKey as keyof IValidationInputs];
      }
    });

    // Set the updated inputs with errors in the state.
    setInputs(updatedInputs);
  };

  // Handler for form submission.
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Validate the form inputs.
    validateCreateAgentSession({
      data: {
        email: inputs.email.value,
        password: inputs.password.value,
      },
      // On success, proceed with login.
      onSuccess: async (validData: IValidationInputs) => await login(validData),
      // On error, handle form errors.
      onError: (errors: IValidationInputs) => handleFormError(errors),
    });
  };

  // Render the SignIn component.
  return (
    <div
      className={styles.container}
      style={{ background: `url(${auth_page_background}) center/cover` }}>
      {/* Container for the title and registration link. */}
      <div className={styles.content_title}>
        {/* Title for the sign in page. */}
        {agency_name && <p className={styles.title}>{agency_name} login</p>}
        {/* Subtitle with a link to the agent registration page. */}

        {registration_link_text ? (
          <span
            className={styles.subtitle}
            dangerouslySetInnerHTML={{
              __html: parseTextWithLinks(
                registration_link_text,
                styles.registrationLink,
              ),
            }}
          />
        ) : null}
      </div>
      {/* Main content container for the sign in form. */}
      <div className={styles.content}>
        {/* The sign in form. */}
        <form className={styles.form} onSubmit={handleSubmit}>
          {/* Input for the email address. */}
          <Input
            className={styles.input}
            value={inputs.email.value}
            valueKey="email"
            label={t("agent email")}
            placeholder={t("Type your email")}
            name="email"
            errorMessage={inputs.email.errorMessage}
            onChange={handleInputChange}
            isRequired
          />

          {/* Input for the password. */}
          <Input
            className={styles.input}
            value={inputs.password.value}
            valueKey="password"
            label={t("password")}
            placeholder={t("Type your password")}
            errorMessage={inputs.password.errorMessage}
            onChange={handleInputChange}
            isRequired
            secured
          />

          {/* Button to submit the form. */}
          <Button label={t("login")} type="submit" loading={isLoading} />
        </form>

        {/* Link to the forgotten password page. */}
        <div className={styles.forgottenLinkContainer}>
          <CustomLink to={FORGOTTEN_PASSWORD} className={styles.forgottenLink}>
            <span>{t("Forgot your password?")}</span>
          </CustomLink>
        </div>
      </div>

      {/* Background styling for the component. */}
      <div className={styles.background} />
    </div>
  );
}

// Export the SignIn component as default.
export default SignIn;
