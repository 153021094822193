// Importing helper functions and components.
import classNames from "classnames"; // Utility for conditionally joining class names together.
import styles from "./index.module.scss"; // Component-specific styles.
import React, { useEffect } from "react"; // React library and useEffect hook.
import { useTranslation } from "react-i18next"; // Internationalization hook.

// Redux hooks for dispatching actions and selecting parts of the state.
import { useTypedDispatch, useTypedSelector } from "../../../store/store";
import { currencyToFormat } from "../../../utils/helpers/currency"; // Utility for currency formatting.

// Action creators for sorting and filtering, along with sort type constants.
import {
  sortName,
  sortDate,
  filterRangePrice,
  SORT_TYPE,
  sortPrice,
} from "../../../store/slices/searchFilterSlice";

// Selector functions for retrieving specific parts of the Redux state.
import {
  selectSearchCalculatePrice,
  selectSearchFilter,
} from "../../../store/selectors";

import Button from "../../shared/Button"; // Reusable button component.
import RangeSlider from "../../shared/RangeSlider"; // Range slider component for price filtering.

// Main component for the search filter feature.
function SearchFilter() {
  const { t } = useTranslation(); // Hook to access translation function.

  // State hook to manage visibility of the filter form.
  const [showFilter, setShowFilter] = React.useState(false);

  // Toggle function for showing/hiding the filter form.
  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  // Rendering the button to toggle filter visibility and the filter form if visible.
  return (
    <div className={classNames(styles.container)}>
      <Button
        variant="dark"
        label={t("Filter results")}
        onClick={handleShowFilter}
      />
      {showFilter && <SearchFilterForm />}
    </div>
  );
}

// Sub-component for the filter form, containing sorting and price filtering options.
function SearchFilterForm() {
  const { t } = useTranslation(); // Hook to access translation function.

  // Redux dispatch hook for sending actions.
  const dispatch = useTypedDispatch();
  // Selectors for retrieving filter configuration and calculated price range from Redux store.
  const filter = useTypedSelector(selectSearchFilter);
  const filterPrices = useTypedSelector(selectSearchCalculatePrice);
  // State hook for managing the selected price range.
  const [rangePrice, setRangePrice] = React.useState([...filterPrices]);

  // Dispatches actions for sorting by name, date, and price.
  const handleSortName = () => {
    dispatch(sortName(!filter.sortName));
  };

  const handleSortDate = () => {
    dispatch(sortDate(!filter.sortDate));
  };

  const handleSortPrice = () => {
    dispatch(sortPrice(!filter.sortPrice));
  };

  // Dispatches an action to filter results within a specific price range.
  const handleRangePrice = (minPrice: number, maxPrice: number) => {
    dispatch(filterRangePrice([minPrice, maxPrice]));
  };

  // Effect hook to initialize the price range filter on component mount/update.
  useEffect(() => {
    handleRangePrice(filterPrices[0], filterPrices[1]);
  }, [filterPrices]);

  // Rendering the filter form with price range selection and sorting buttons.
  return (
    <div className={classNames(styles.content)}>
      <div className={classNames(styles.rangeSliderContainer)}>
        <p className={classNames(styles.labelRangePrice)}>
          {currencyToFormat(rangePrice[0], "USD")} -{" "}
          {currencyToFormat(rangePrice[1], "USD")}
        </p>

        <RangeSlider
          min={filterPrices[0]}
          max={filterPrices[1]}
          onMouseUp={(value) => handleRangePrice(value.min, value.max)}
          onChange={(value) => setRangePrice([value.min, value.max])}
        />
      </div>

      {/* Buttons for toggling sort order by name, date, and price, 
          with visual indication of the current sort state. */}
      <Button
        className={classNames(
          {
            [styles.activeButton]: filter.sortType === SORT_TYPE.NANE,
          },
          styles.button,
        )}
        label={`${t("sort name")}: ${filter.sortName ? "A-Z" : "Z-A"}`}
        icon={filter.sortName ? "FiArrowDown" : "FiArrowUp"}
        onClick={handleSortName}
      />

      <Button
        label={t("sort date")}
        className={classNames(
          {
            [styles.activeButton]: filter.sortType === SORT_TYPE.DATE,
          },
          styles.button,
        )}
        icon={filter.sortDate ? "FiArrowDown" : "FiArrowUp"}
        onClick={handleSortDate}
      />

      <Button
        label={t("sort price")}
        className={classNames(
          {
            [styles.activeButton]: filter.sortType === SORT_TYPE.PRICE,
          },
          styles.button,
        )}
        icon={filter.sortPrice ? "FiArrowDown" : "FiArrowUp"}
        onClick={handleSortPrice}
      />
    </div>
  );
}

// Exporting the SearchFilter component for use elsewhere in the application.
export default SearchFilter;
